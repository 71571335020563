import React from "react";
import "./testimonial-card.scss";
import quotes from "../../assets/images/quotes.svg"
import rating from "../../assets/images/rating.svg"

const TestimonialCard = ({img, review, name, relation}) => {
  return (
    <div className="testimonial-card">
      <div className="review">
        <p>
          <span>
            <img src={quotes} alt="quotation-open" />
          </span>
          {review}
          <span>
            <img src={quotes} alt="quotation-close" />
          </span>
        </p>
      </div>

      <div className="info">
        <div className="left">
          <img src={img} alt="person-img" />
        </div>
        <div className="right">
          <p className="name">{name}</p>
          <p className="relation">{relation}</p>
          <img src={rating} alt="rating" />
        </div>
      </div>
    </div>
  );
};

export default TestimonialCard;
